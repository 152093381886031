import React, { useRef } from "react";
import Layout from "./Layouts/Layout";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import {
  LandingPage,
  Events,
  About,
  Blog,
  SinglePost,
  Commercial,
  Haydropack,
  HowItWorks,
  NewDirections,
  Relief_Impact,
  Residential,
  Product,
  SingleProduct,
  Contact,
  GetAQuote,
  FAQs,
  Guides,
  PriceMatchGuarantee,
  PrivacyPolicy,
  Terms,
} from "./Pages";
import Home from "./Pages/New_Pages/Home";
import NewAbout from "./Pages/New_Pages/About";
import NewQuote from "./Pages/New_Pages/quote";
import { Footer, Header } from "./Components";
import NotFound from "./Pages/NotFound";
function App() {
  return (
    <React.Fragment>
      {/*  --------- Header  ---------   */}
      <Header />
      <Routes>
        {/*  --------- New  Pages ---------   */}
        {/*<Route path="/" element={<Home />} />*/}
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<NewAbout />} />
        <Route path="/quote" element={<NewQuote />} />
        {/*  --------- Static General Pages ---------   */}
        {/*<Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/events" element={<Events />} />*/}
        {/*  --------- Dynamic Blog Pages ---------   */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<SinglePost />} />
        {/*  --------- Static Product Pages ---------   */}
        <Route path="/haydropack" element={<Haydropack />} />
        <Route path="/solutions/residential" element={<Residential />} />
        <Route path="/solutions/relief&impact" element={<Relief_Impact />} />

        <Route path="/solutions/commercial" element={<Commercial />} />
        <Route path="/new-directions" element={<NewDirections />} />
        <Route path="/how-query-water-works" element={<HowItWorks />} />

        {/*  --------- Dynamic Product Pages ---------   */}
        <Route path="/Products" element={<Product />} />
        <Route path="/Products/:id" element={<SingleProduct />} />
        {/*  --------- Contact General Pages ---------   */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/get-a-quote" element={<GetAQuote />} />
        {/*  --------- Other General Pages ----------   */}
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<Terms />} />
        <Route path="/guides" element={<Guides />} />
        <Route
          path="/price-match-guarantee"
          element={<PriceMatchGuarantee />}
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>

      {/*  --------- Footer ---------   */}
      <Footer />
    </React.Fragment>
  );
}

export default App;
