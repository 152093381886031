import { Box, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";

import qw1000 from "../../assets/product_image-1.png";
import qw2000 from "../../assets/product_image-2.png";
import qw20 from "../../assets/product_image-3.png";

import bg from "../../assets/bgelement.svg";

import QuoteBtn from "./mini-components/QuoteBtn";

const products = [
  { id: 2, img: qw1000, index: "02" },
  { id: 3, img: qw2000, index: "03" },
  { id: 3, img: qw20, index: "03" },
];

const Hero = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: true,
  };

  return (
    <>
      <Box sx={{ display: "grid" }}>
        <Box
          sx={{
            display: { md: "flex", xs: "block" },
            position: "relative",
            zIndex: 2,
            marginTop: { md: "-1%", xs: "-0%" },
            backgroundColor: "#ddd",
            height: "700px",
            padding: {
              xl: "20px",
              lg: "50px",
              md: "0px",
              sm: "30px",
              xs: "30px",
            },
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
            width: "auto",
          }}
        >
          <Box
            sx={{
              padding: { md: "50px", xs: "none" },
              marginTop: { xl: "7%", lg: "5%", md: "2%", sm: "5%", xs: "25%" },
              marginLeft: { xl: "7%", lg: "0%", md: "2%", sm: "5%", xs: "0%" },
            }}
          >
            <Box sx={{ paddingRight: { md: "0", xs: "5%" } }}>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 600,
                  fontSize: { xl: "48px", lg: "48px", md: "35px", xs: "40px" },
                  lineHeight: "1.25",
                  letterSpacing: "-0.02em",
                  textAlign: { xs: "center", md: "left" },
                }}
                color="#000"
              >
                Never run dry with Waterwell <br /> atmospheric water
                generators!
              </Typography>
            </Box>
            <Box
              sx={{
                paddingTop: "20px",
                paddingRight: { md: "none", xs: "0%" },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 600,
                  fontSize: { md: "24px", xs: "22px" },
                  lineHeight: "1.33",
                  textAlign: { xs: "center", md: "left" },
                }}
                color="#000"
              >
                Proven leaders in Europe and beyond, our cutting-edge <br />
                texhnology harnesses the power of the air to deliver fresh,
                clean water.{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                paddingTop: "10px",
                paddingRight: { md: "10%", xs: "0" },
                marginRight: { md: "20%", xs: "0" },
                marginLeft: { xs: "15%", md: "0" },
              }}
            >
              <Typography
                variant="p"
                sx={{
                  fontWeight: 400,
                  fontSize: { md: "20px", xs: "19px" },
                  lineHeight: "1.5",
                  textAlign: { xs: "center", md: "left" },
                }}
                color="#000"
              >
                Upgrade your water security today
              </Typography>
            </Box>
            <Box
              sx={{
                display: { md: "flex", xs: "block" },
                marginTop: "35px",
              }}
            >
              <Box
                sx={{
                  marginRight: "10px",
                  marginLeft: { md: "0px", xs: "25%" },
                }}
              >
                <QuoteBtn title="Get A Quote" href="/get-a-quote" />
              </Box>
              <Box
                sx={{
                  marginRight: "10px",
                  marginLeft: { md: "0px", xs: "18%" },
                }}
              ></Box>
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: "-8%",
              display: { xs: "none", md: "block" },
            }}
          >
            <Box sx={{ zIndex: 1, marginTop: "10%", marginLeft: "0%" }}>
              <img alt="" src={bg} />
            </Box>
            <Box
              elevation={6}
              sx={{
                borderRadius: "25px",
                width: { md: "500px", xs: "200px" },
                padding: "30px",
                marginTop: "-80%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                justifyItems: "center",
                marginLeft: { md: "-10%", xs: "0" },
                backgroundColor: "transparent",
              }}
            >
              <Box sx={{ display: { md: "contents", xs: "none" }, zIndex: 2 }}>
                <Slider {...settings}>
                  {products &&
                    products?.map((item) => (
                      <>
                        <Box key={item.id}>
                          <img
                            className="Image"
                            alt=""
                            style={{ width: "350px", marginLeft: "10%" }}
                            src={item.img}
                          />
                        </Box>
                      </>
                    ))}
                </Slider>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            height: { md: "150px", xs: "70vh" },
            border: "solid 2px #005096",
            borderRadius: "20px",
            justifyContent: "space-around",
            justifyItems: "center",
            alignItems: "center",
            alignContent: "center",
            display: { md: "flex", xs: "none" },
            marginX: "20%",
            marginTop: "-5%",
            padding: "20px",
            zIndex: 4,
          }}
        >
          <Box sx={{ marginTop: "0%", marginRight: "1%" }}>
            <Typography
              variant="h2"
              sx={{ fontSize: "50px", fontWeight: 600, textAlign: "center" }}
            >
              10K
            </Typography>
            <Typography variant="p" sx={{ fontSize: "20px", fontWeight: 600 }}>
              Units Installed
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#005096",
              height: "200px",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
              alignContent: "center",
              paddingX: { md: "20px", xs: "0" },
              borderEndEndRadius: "20px",
              borderEndStartRadius: "50%",
              borderStartStartRadius: "20px",
              borderStartEndRadius: "50%",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { md: "60px", xs: "30px" },
                fontWeight: 600,
                textAlign: "center",
                paddingTop: "40px",
                color: "#fff",
              }}
            >
              22+
            </Typography>
            <Typography
              variant="p"
              sx={{ fontSize: "20px", fontWeight: 600, color: "#ddd" }}
            >
              Years of experince
            </Typography>
          </Box>

          <Box sx={{ marginTop: "0%" }}>
            <Typography
              variant="h2"
              sx={{ fontSize: "50px", fontWeight: 600, textAlign: "center" }}
            >
              28
            </Typography>
            <Typography variant="p" sx={{ fontSize: "20px", fontWeight: 600 }}>
              International Markets
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Hero;
