import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import wind from "../../assets/wind.gif";
import ui from "../../assets/ui.gif";
import database from "../../assets/database.gif";
import bg from "../../assets/bgelement.svg";
import Q20 from "../../assets/product_image-2.png";

const HPWSection = () => {
  return (
    <>
      <Grid container lg={12}>
        <Grid item lg={4}>
          <Box
            sx={{
              marginLeft: { lg: "90px", md: "0px", xs: "0" },
              marginTop: "5%",
            }}
          >
            <Box
              sx={{
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingBottom: "0%",
                position: "relative",
              }}
            >
              <Typography
                variant="h3"
                fontFamily="klarheit_grotesk_regular"
                fontWeight={500}
                lineHeight="1.2em"
                textAlign="left"
                sx={{
                  fontWeight: 400,
                  textAlign: { xs: "center", md: "center", lg: "left" },
                  marginTop: { md: "15%", lg: "50%" },
                  fontSize: { md: "40px", xs: "25px" },
                }}
              >
                Our atmospheric water generators reliably produce clean drinking
                water wherever you need it, whenever you need it.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={4} sx={{ marginBottom: { md: "15%" } }}>
          <Box
            sx={{
              marginTop: { lg: "15%", md: "0" },
              marginBottom: "10%",
              paddingLeft: { md: "-10%", xs: "-5%" },
            }}
          >
            <Box sx={{ marginLeft: { md: "-2%", lg: "-2%" } }}>
              <img
                src={Q20}
                width="450px"
                alt="Aquaria"
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  position: "relative",
                  zIndex: 1,
                  top: "80px",
                }}
              />
            </Box>
            <Box
              sx={{
                paddingLeft: 9,
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
                position: "absolute",
                left: { lg: "30%", md: "0%", xs: "0%" },
                top: { lg: "135%", md: "110%" },
                zIndex: 0,
                display: { md: "block", xs: "none" },
              }}
            >
              <img src={bg} alt="circle" style={{ width: "450px" }} />
            </Box>
          </Box>
        </Grid>
        <Grid item lg={4} sx={{ display: { md: "block", xs: "none" } }}>
          <Box
            sx={{
              marginLeft: { lg: "-30%", md: "-15%" },
              marginY: { lg: "15%", md: "10%" },
            }}
          >
            <Box>
              <Paper
                sx={{
                  backgroundColor: "#fff",
                  width: "100px",
                  height: "100px",
                  marginTop: { lg: "20%", md: "70px" },
                  marginLeft: "100px",
                  borderRadius: "100%",
                  zIndex: 1,
                  position: "relative",
                }}
                elevation={6}
              >
                <img
                  alt=""
                  src={wind}
                  width="65%"
                  height="65%"
                  style={{ marginTop: "20px", marginLeft: "15px" }}
                />
              </Paper>
              <Divider
                variant="inset"
                width="500px"
                sx={{ marginTop: "-50px", zIndex: 0, marginLeft: "0px" }}
              />
            </Box>
            <Typography
              sx={{
                paddingLeft: { lg: "35%", md: "40%" },
              }}
            >
              <span style={{ fontWeight: "bold" }}>Fresh air intake: </span>{" "}
              Free-flowing air is
              <br /> drawn into the system
            </Typography>
            <Box sx={{}}>
              <Paper
                sx={{
                  backgroundColor: "#fff",
                  width: "130px",
                  height: "130px",
                  marginTop: "30px",
                  marginLeft: "120px",
                  borderRadius: "100%",
                  zIndex: 1,
                  position: "relative",
                }}
                elevation={6}
              >
                <img
                  alt=""
                  src={database}
                  width="65%"
                  height="65%"
                  style={{ marginTop: "20px", marginLeft: "20px" }}
                />
              </Paper>
              <Divider
                variant="inset"
                width="500px"
                sx={{ marginTop: "-60px", zIndex: 0, marginLeft: "0px" }}
              />
              <Typography
                sx={{
                  paddingLeft: { lg: "40%", md: "45%" },
                }}
              >
                <span style={{ fontWeight: "bold" }}>Advanced filtration:</span>
                {""} State-of-the-art <br />
                filters cleanse and purify the air, <br /> removing impurities
                and contaminants.
              </Typography>
            </Box>
            <Box sx={{}}>
              <Paper
                sx={{
                  backgroundColor: "#fff",
                  width: "90px",
                  height: "90px",
                  marginTop: "20px",
                  marginLeft: "100px",
                  borderRadius: "100%",
                  position: "relative",
                }}
                elevation={6}
              >
                <img
                  alt=""
                  src={ui}
                  width="65%"
                  height="65%"
                  style={{ marginTop: "20px", marginLeft: "15px" }}
                />
              </Paper>
              <Divider
                variant="inset"
                width="500px"
                sx={{ marginTop: "-45px", zIndex: 0, marginLeft: "0px" }}
              />
              <Typography
                sx={{
                  paddingLeft: { lg: "35%", md: "35%" },
                }}
              >
                <span style={{ fontWeight: "bold" }}>Pure water output:</span>
                The filtered air is transformed
                <br /> into safe and clean drinking water, ready for use.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HPWSection;
