import { Alert, Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import bg from "../../assets/bgelement.svg";

const TrustedWater = () => {
  return (
    <>
      <Box
        sx={{
          display: { md: "flex", xs: "block" },
          position: "relative",
          zIndex: 2,
          marginTop: { lg: "10%", md: "5%", xs: "-0%" },
          backgroundColor: "#ddd",
          height: { md: "auto", xs: "auto" },
          padding: { md: "96px", xs: "0px" },
          justifyContent: "center",
          alignContent: "center",
          justifyItems: "center",
          alignItems: "center",
          border: "solid 2px  #005096",
        }}
      >
        <Grid container lg={12}>
          <Grid item lg={6}>
            <Box
              sx={{
                padding: { md: "30px", xs: "20px" },
                alignItems: "flex-start",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h2"
                color="#000"
                sx={{
                  fontWeight: 600,
                  fontSize: { lg: "40px", md: "48px", xs: "45px" },
                  lineHeight: "1.8",
                  letterSpacing: "-0.02em",
                  textAlign: { xs: "left", md: "left" },
                }}
              >
                Elevate Your Trust in Water Quality: Our Pursuit of Perfection
              </Typography>
              <Typography
                variant="h5"
                color="#000"
                sx={{
                  fontFamily: "klarheit_grotesk_regular",
                  fontWeight: 300,
                  fontStyle: "normal",
                  fontSize: { lg: "26px", md: "28px" },
                  lineHeight: 1.5,
                  marginLeft: "5px",
                  marginRight: "40px",
                }}
              >
                Waterwell's cutting-edge technology ensures that every drop of
                water produced adheres to the stringent guidelines set forth by
                both the EPA and WHO. Whether your needs dictate compliance with
                these regulations or simply the highest possible quality, rest
                assured that our systems will deliver with precision and
                reliability.
              </Typography>
              <Stack
                sx={{
                  width: { md: "80%", xs: "100%", display: "none" },
                  paddingTop: "10%",
                }}
                spacing={2}
              >
                <Alert severity="success">
                  *Please see{" "}
                  <a
                    href="/faqs"
                    style={{ color: "#000", paddingLeft: "10px" }}
                  >
                    FAQ clean drinking water section!
                  </a>{" "}
                  - {""}
                  for details.
                </Alert>
              </Stack>
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box
              sx={{
                margin: { md: "100px", xs: "25px" },
                borderRadius: "50px",
                marginLeft: { lg: "20%", md: "10%", xs: "5%" },
                marginTop: "10%",
              }}
            >
              <video
                autoPlay
                muted
                loop
                playsInline
                style={{
                  width: "100%",
                  borderRadius: "50px",
                  border: "solid 2px #fff",
                }}
              >
                <source
                  src="https://player.vimeo.com/progressive_redirect/playback/834433526/rendition/source/file.mp4?loc=external&log_user=0&signature=ab1da2e383728d74d952f1d30181884bfda654a1c6f61dc1b335fe8c307a415d"
                  type="video/mp4"
                />
              </video>
            </Box>
          </Grid>
          <Box
            sx={{
              marginTop: { lg: "-30%", md: "" },
              marginLeft: { md: "15%", xs: "10%" },
              display: { lg: "flex", md: "none", xs: "none" },
            }}
          >
            <img alt="" src={bg} width="500px" />
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default TrustedWater;
