import {
  Box,
  Button,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React from "react";
import AquiraImg from "../../assets/Aquaria.png";
import QuoteBtn from "../../Components/New/mini-components/QuoteBtn";
import { useState } from "react";
import Q20 from "../../assets/product_image-1.png";
import { Footer, Header } from "../../Components";

const Contact = () => {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(` Form Submitted, thanks ${inputs.firstName} ...  `);
  };
  return (
    <>
      <Box>
        <Grid container lg={12} sx={{ paddingTop: "80px" }}>
          <Grid
            item
            lg={6}
            sx={{
              backgroundColor: "#ddd",
              height: "340px",
              marginLeft: { lg: "-25px", md: "-25px", xs: "0" },
              display: { xs: "none", md: "flex" },
            }}
          >
            <Box sx={{ marginTop: "-100px", marginLeft: { lg: "10%" } }}>
              <img
                alt="Aquaria"
                src={Q20}
                style={{
                  marginTop: "140px",
                  marginLeft: "30%",
                  padding: "10px",
                }}
                width="400px"
                height="400px"
              />
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sx={{ paddingLeft: { md: "80px", xs: "25px" } }}
          >
            <Box>
              <Typography
                variant="h3"
                fontWeight="600"
                sx={{ paddingTop: "20px", paddingBottom: "10px" }}
              >
                Contact Us
              </Typography>
              <span>Get in touch, we’d love to hear from you.</span>
            </Box>
            <Box>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3, mr: { lg: 10, md: 0 } }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      value={inputs.firstName || ""}
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      value={inputs.lastName || ""}
                      onChange={handleChange}
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      value={inputs.email || ""}
                      onChange={handleChange}
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      fullWidth
                      name="phone"
                      value={inputs.phone || ""}
                      onChange={handleChange}
                      label="Phone"
                      type="tel"
                      id="phone"
                      autoComplete="new-phone"
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      fullWidth
                      name="message"
                      value={inputs.message || ""}
                      onChange={handleChange}
                      type="message"
                      id="message"
                      placeholder="Type message here..."
                    />
                  </Grid>
                </Grid>
                <Box sx={{ marginY: "20px" }}>
                  <QuoteBtn
                    type="submit"
                    title="Submit"
                    onClick={handleSubmit}
                    onSubmit={handleSubmit}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Contact;
