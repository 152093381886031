import React from "react";
import Hero from "../../Components/New/Hero";
import TrustedWater from "../../Components/TrustedWater/TrustedWater";
import HPWSection from "../../Components/HPWSection/HPWSection";
import PASection from "../../Components/PASection/PASection";
import CTA from "../../Components/New/CTA";
import HelpingSolutions from "../../Components/New/HelpingSolutions";
import { Footer, Header } from "../../Components";
import { Box } from "@mui/material";

const Home = () => {
  return (
    <>
      {/*Hero_Section */}

      <Hero />
      {/*How-it-works_Section*/}
      <HPWSection />
      <HelpingSolutions />
      <PASection />
      <TrustedWater />
      {/*Served_Section*/}
      {/*Why-Water-Well_Section*/}
      {/*What-our-customers-say_Section*/}
      {/*<WOCS />*/}
      {/*Solutions_Section*/}
      {/*Partners_Section*/}
      {/*CTA_Section*/}

      {/*FAQ_Section*/}
      {/*Footer */}
    </>
  );
};

export default Home;
