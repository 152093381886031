import { Box, Typography } from "@mui/material";
import React from "react";
import ProductQ20 from "../CTA/custom/ProductQ20";
import ProductQ1000 from "../CTA/custom/ProductQ1000";
import ProductQ2000 from "../CTA/custom/ProductQ2000";

import womenWater from "../../assets/woman-drinking-water-after-exercise.jpg";
import home from "../../assets/product_image-4.png";
import House from "../../assets/way-white-house.jpg";
import Solar from "../../assets/product_image-1.png";
import Slider from "react-slick";
import qw2000 from "../../assets/product_image-2.png";
import natural from "../../assets/ruins-derelict.jpg";

const HelpingSolutions = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: true,
    arrow: true,
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#ddd",
          height: { lg: "120vh", md: "70vh", xs: "65vh" },
          zIndex: 0,
          position: "relative",
        }}
      >
        <Box sx={{ paddingTop: { md: "5%", xs: "20%" }, zIndex: 2 }}>
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              fontWeight: 600,
              fontSize: { xs: "45px" },
            }}
          >
            Transforming the {""}
            <span style={{ color: "#005096" }}>Air into Liquid </span> Gold
          </Typography>
        </Box>
        <Box
          sx={{
            marginX: { lg: "20%", md: "15%", xs: "3%" },
            marginY: { lg: "2%", md: "2%", xs: "5%" },
            justifyContent: "center",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
            paddingX: "2%",
            textAlign: "center",
          }}
        >
          <Typography
            variant="p"
            textAlign="center"
            sx={{ fontWeight: 400, fontSize: "20px" }}
          >
            Innovative technologies convert air into pure drinking water,
            promising 100% independence from traditional supplies.Our game
            changing units transform the way we live, work,and play, ensuring
            reliable clean water for all aspects of life.
          </Typography>
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "#fff",
            borderRadius: "25px",
            marginX: { lg: "30px", md: "30px" },
            marginY: { lg: "0px", md: "80px" },

            height: "32vw",
            border: "solid 2px #005096 ",
            display: { md: "block", xs: "none" },
          }}
        >
          <Slider {...settings}>
            <Box
              sx={{
                paddingX: { md: "8%", lg: "8%" },
                position: "relative",
              }}
            >
              <ProductQ1000
                BG={House}
                productImg={Solar}
                header="Industrial"
                subtitle="Waterwell's devices can significantly reduce water costs and increase productivity for industries which rely heavily on clean water."
              />
            </Box>
            <Box
              sx={{
                paddingX: { md: "10%", lg: "8%" },
                position: "relative",
              }}
            >
              <ProductQ2000
                BG={natural}
                productImg={qw2000}
                header="Disasters Recovery"
                subtitle="Waterwell atmospheric generators can provide a stable source of clean drinking water, helping to save lives and support recovery efforts."
              />
            </Box>
            <Box
              sx={{ paddingX: { md: "10%", lg: "8%" }, position: "relative" }}
            >
              <ProductQ20
                BG={womenWater}
                productImg={home}
                header="Home And Offices"
                subtitle="Wellwater atmospheric generators provide a reliable source of clean drinking water for homes and offices, eliminating the need for expensive bottled water or municipal water connections."
              />
            </Box>
          </Slider>
        </Box>
      </Box>
    </>
  );
};

export default HelpingSolutions;
