import { Box, Container } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const Layout = ({ children }) => {
  return (
    <>
      <Box>{children}</Box>
    </>
  );
};

Layout.prototype = {
  classes: PropTypes.object.isRequired,
};

export default Layout;
