import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import q2000 from "../../assets/product_image-3.png";
import drips from "../../assets/icon_drips.svg";
import grid from "../../assets/icon_grid.svg";
import water from "../../assets/icon_water.svg";

const productAd = [
  {
    id: 1,
    icon: drips,
    title: "ٌRESERVE WATER SUPPLY",
    des: "Waterwell's atmospheric water generators represent a cutting-edge solution to the challenges posed by climate change, contamination, and disruptive events. Leveraging proprietary technology, we convert ambient air into a reliable source of clean water, providing a robust backup plan for   residential, communal, and commercial applications .",
  },
  {
    id: 2,
    icon: water,
    title: "PURE AND PRISTINE HYDRATION",
    des: "Say goodbye to water worries with Waterwell's atmospheric water generators. These innovative devices simplify access to crystal-clear, EPA-compliant drinking water, making it readily available whenever and wherever you need it. Just drop one in, and you're good to go!",
  },
  {
    id: 3,
    icon: grid,
    title: "WATER SELF-SUFFICIENCY",
    des: "Embracing off-grid living grants you autonomy, yet securing a reliable water supply remains a significant hurdle. Waterwell seamlessly aligns with your commitment to self-suffciency by delivering a renewable energy-powered water station that accompanies you everywhere your adventures take you!",
  },
];

const PASection = () => {
  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            backgroundColor: "#ddd",
            marginLeft: { lg: "20%", md: "5%", xs: "0" },
            marginRight: { lg: "20%", md: "5%", xs: "0" },
            marginTop: "5%",
            paddingTop: "5%",
            paddingBottom: "3%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              marginBottom: "5%",
              marginLeft: { md: "10%", xs: "25px" },
              marginRight: { md: "5%", xs: "25px" },
            }}
          >
            <Typography
              variant="h2"
              fontWeight={600}
              lineHeight="1.2em"
              sx={{
                fontWeight: 500,
                fontSize: { md: "35px", xs: "35px" },
                lineHeight: "1.3",
                letterSpacing: "0.03em",
                textAlign: { xs: "left", md: "left" },
              }}
            >
              <span style={{ color: "#005096" }}>
                SECURING WATER RESILIENCE:
              </span>{" "}
              Protecting Your Access to Life's Essential Resources Admidst
              Distrutions, Drought, and Climate Uncertainty.
            </Typography>
          </Box>
          <Grid container lg={12} spacing={2} sx={{}}>
            <Grid item lg={8}>
              <Box sx={{ marginLeft: { lg: "10%", md: "6%", xs: "0" } }}>
                {productAd &&
                  productAd?.map((item) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          padding: { md: "30px", lg: "10px", xs: "20px" },
                        }}
                      >
                        <Box sx={{ paddingX: "10px" }} key={item.id}>
                          <img alt="" src={item.icon} width="60px" />
                        </Box>
                        <Box sx={{}}>
                          <Typography
                            variant="h4"
                            sx={{ fontSize: { lg: "28px", xs: "25px" } }}
                          >
                            {item.title}
                          </Typography>
                          <Box
                            sx={{
                              marginRight: { lg: "10%", md: "20%", xs: "19%" },
                            }}
                          >
                            <Typography variant="p">{item.des}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  ))}
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box
                sx={{
                  marginLeft: { lg: "50px", md: "150px" },
                  paddingTop: { lg: "70%", md: "5%" },

                  display: { md: "flex", xs: "none" },
                }}
              >
                <img alt="" src={q2000} width="550px" />
              </Box>
              <Box
                sx={{
                  marginLeft: "80px",
                  marginBottom: "-20px",
                  display: { md: "none", xs: "flex" },
                }}
              >
                <img alt="" src={q2000} width="300px" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default PASection;
