import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Timeline from "../../Components/New/Timeline";
import Radko from "../../assets/Radko.jpg";
import Filip from "../../assets/Filip.jpg";
import Ales from "../../assets/ales.jpg";
import Ahmed from "../../assets/dr-ahmed.jpg";
import Richard from "../../assets/Richard.jpg";
import logo from "../../assets/logo.svg";
import { Footer, Header } from "../../Components";

const data = [
  { id: 1, img: Radko, name: "Radko Souc̄ek", title: `President  CEO` },

  {
    id: 3,
    img: Ahmed,
    name: "Dr. Ahmed Faisal",
    title: `International  Business Director`,
  },
  {
    id: 5,
    img: Richard,
    name: `Richard  Mikulenka`,
    title: `Chief IT and  Development`,
  },
  {
    id: 4,
    img: Ales,
    name: `Mr. Ales  Pachmann Ph.D`,
    title: `Research and  Development`,
  },
];
const About = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#ddd",
          height: "auto",
          padding: "60px",
          width: "auto",
          paddingTop: { lg: "5%", md: "5%", xs: "20%" },
        }}
      >
        <Box
          sx={{
            display: { md: "flex", xs: "flex" },
            padding: { md: "60px" },
          }}
        >
          <Grid container lg={12}>
            <Grid item lg={7}>
              <Box sx={{ paddingRight: { md: "25%", xs: "5%" } }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontWeight: 600,
                    fontSize: { md: "60px", xs: "35px" },
                    lineHeight: "1.25",
                    letterSpacing: "-0.02em",
                    textAlign: { xs: "left", md: "left" },
                    paddingBottom: "20px",
                  }}
                  color="#000"
                >
                  We are the future of atmospheric water generators
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    marginRight: { md: "30px" },
                    fontWeight: 400,
                    fontSize: { md: "20px", xs: "17px" },
                    lineHeight: "1.5",
                    textAlign: { xs: "left", md: "left" },
                  }}
                  color="#000"
                >
                  Our company is currently developing models that leverage
                  renewable resources and their production to re-green desert
                  environments. Our focus lies on three core areas: renewable
                  biochar, hydrogen technologies, and geoengineering. These
                  innovations aim to promote sustainable living and mitigate the
                  effects of climate change.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={5} sx={{}}>
              <Box
                sx={{
                  padding: "30px",
                  marginTop: "20%",
                  marginLeft: { md: "-50px", lg: "0" },
                  display: { md: "flex", xs: "none" },
                }}
              >
                <img alt="" src={logo} width="500px" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          height: { md: "130vh", lg: "130vh", xs: "100vh" },
          padding: { md: "60px", xs: "0" },
        }}
      >
        <Box sx={{ padding: { md: "60px", xs: "0" } }}>
          <Box sx={{ display: { md: "flex", xs: "none" } }}>
            <Typography variant="h3">Timeline</Typography>
          </Box>
          <Box>
            <Timeline />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#ddd",
          height: "100vh",
          padding: { md: "60px", xs: "0" },
          display: { xs: "flex", md: "flex" },
          marginTop: { xs: "35%", md: "0" },
        }}
      >
        <Box sx={{ padding: { md: "60px", xs: "0" } }}>
          <Box
            sx={{
              paddingLeft: { xs: "35px", md: "0" },
              paddingTop: { xs: "35px", md: "0" },
            }}
          >
            <Typography
              variant="h3"
              sx={{ fontSize: { xs: "45px" }, textAlign: { xs: "center" } }}
            >
              Our Team
            </Typography>
          </Box>
          <Box sx={{ padding: "20px", marginY: "20px" }}>
            <Grid
              container
              lg={12}
              spacing={2}
              sx={{ justifyContent: "space-around" }}
            >
              {data &&
                data?.map((item) => (
                  <>
                    <Grid item lg={5} xs={12} key={item.id}>
                      <Paper
                        sx={{
                          backgroundColor: "#fff",
                          textAlign: "center",
                          borderRadius: "18px",
                          padding: "20px",
                          margin: "20px",
                        }}
                        elevation={6}
                      >
                        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                          <Typography
                            variant="h3"
                            sx={{ fontSize: "25px", fontWeight: 500 }}
                          >
                            {item.name}
                          </Typography>
                          <Box sx={{ marginY: "10px" }}>
                            <Typography variant="h3" sx={{ fontSize: "20px" }}>
                              {item.title}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  </>
                ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      {/*<Box sx={{ height: "80vh", padding: "60px" }}>
  <Box sx={{ padding: "60px", textAlign: "center" }}>
    <Box>
      <Typography variant="h3">Global Service</Typography>
    </Box>
    <Box></Box>
  </Box>
</Box>
  */}
    </>
  );
};

export default About;
