import { Box, Grid, ListItem, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/logo.svg";
import CTA from "../New/CTA";
import { NavLink } from "react-router-dom";
import ContactForm from "../Contact_Form/ContactForm";
const FRow = [
  { id: 1, title: "Home", href: "/" },
  { id: 2, title: "About", href: "/about" },
];
const SRow = [{ id: 1, title: "Contact Us", href: "/contact" }];

const Footer = () => {
  return (
    <>
      <footer>
        <CTA />
        <Box sx={{ backgroundColor: "#ddd", padding: "30px" }}>
          <Grid container>
            <Grid item lg={3} xs={12} md={12} sx={{ textAlign: "center" }}>
              <NavLink to="/">
                <img alt="" src={logo} width="300px" />
              </NavLink>
            </Grid>
            <Grid item lg={3} md={4} xs={12}>
              {FRow.map((item) => (
                <ListItem
                  key={item.id}
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    marginLeft: { lg: "80%", md: "120%", sm: "42%", xs: "35%" },
                  }}
                >
                  <NavLink
                    to={item.href}
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                  >
                    <Typography sx={{ fontSize: { xs: "20px" } }}>
                      {item.title}
                    </Typography>
                  </NavLink>
                </ListItem>
              ))}
            </Grid>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              {SRow.map((item) => (
                <ListItem
                  key={item.id}
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    marginLeft: { md: "50%", sm: "40%", xs: "30%" },
                  }}
                >
                  <NavLink
                    to={item.href}
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                  >
                    <Typography sx={{ fontSize: { xs: "20px" } }}>
                      {item.title}
                    </Typography>
                  </NavLink>
                </ListItem>
              ))}
            </Grid>
            <Grid item lg={3} xs={12}>
              <Box
                sx={{
                  marginY: "20px",
                  textAlign: "center",
                  paddingLeft: { lg: "0", md: "30px" },
                }}
              >
                <Typography variant="h4" color="#000">
                  Keep in Touch
                </Typography>
                <Box
                  sx={{ marginTop: "10px", paddingX: { lg: "0", md: "20%" } }}
                >
                  <span style={{ color: "#000", fontSize: "17px" }}>
                    Stay up to date with the latest educational resources around
                    climate change, clean water, water security and community
                    resilience.
                  </span>
                  <br />
                  <Box sx={{ mt: "15px" }} xs={12}>
                    <Typography sx={{}} variant="h5">
                      info@waterwell.com
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              container
              sx={{ marginY: "20px", textAlign: "center" }}
              spacing={2}
            >
              <Grid
                item
                lg={2}
                xs={5}
                sx={{
                  marginLeft: { lg: "0", md: "43%", sm: "39%", xs: "32%" },
                }}
              >
                <ListItem sx={{ color: "#000" }}>
                  {" "}
                  <NavLink
                    to="/privacy-policy"
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                  >
                    <Typography>Privacy Policy</Typography>
                  </NavLink>
                </ListItem>
              </Grid>
              <Grid
                item
                lg={2}
                xs={12}
                sx={{
                  marginLeft: { lg: "0", md: "43%", sm: "40%", xs: "32%" },
                }}
              >
                <ListItem sx={{ color: "#000" }}>
                  <NavLink
                    to="/terms-of-use"
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                  >
                    <Typography>Terms of Use</Typography>
                  </NavLink>
                </ListItem>
              </Grid>
              <Grid
                item
                lg={2}
                xs={12}
                sx={{
                  marginLeft: { lg: "0", md: "40%", sm: "35%", xs: "25%" },
                }}
              >
                <ListItem sx={{ color: "#000" }}>
                  <NavLink
                    to="/price-match-guarantee"
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                  >
                    <Typography>Price Match Guarantee</Typography>
                  </NavLink>
                </ListItem>
              </Grid>
              <Grid
                item
                lg={4}
                sx={{
                  marginLeft: { lg: "0", md: "35%", sm: "30%", xs: "15%" },
                }}
              >
                <ListItem xs={12} sx={{ color: "#000" }}>
                  2023 Waterwell. All rights reserved.
                </ListItem>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </footer>
    </>
  );
};

export default Footer;
