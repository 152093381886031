import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import QuoteBtn from "./mini-components/QuoteBtn";
import athelet from "../../assets/athletic.jpg";
import logo from "../../assets/logo.svg";
import { NavLink } from "react-router-dom";
const HIW = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#005096",

          zIndex: 0,
          position: "relative",
          paddingTop: "5%",
          paddingBottom: "5%",
        }}
      >
        <Box sx={{ zIndex: 2 }}>
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              color: "#fff",
              fontSize: { md: "50px", xs: "30px" },
              marginTop: { xs: "30px" },
            }}
          >
            The Future of Water is Here: Waterwell's
            <br /> Atmospheric Water Generators
          </Typography>
        </Box>

        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              paddingLeft: { md: "30%", xs: "10%" },
              paddingRight: { md: "30%", xs: "10%" },
              marginTop: "20px",
            }}
          >
            <Typography variant="h7" sx={{ color: "#fff", fontSize: "17px" }}>
              Ready to experience the future of water? Contact us today to
              secure your advance order and discover the perfect solution for
              your needs. We're here to help bring clean, sustainable water to
              your doorstep!
            </Typography>
          </Box>
        </Box>
        <Box sx={{ textAlign: "center", marginTop: "3%" }}>
          <Divider sx={{ Divider: "#fff" }}>
            <Box
              sx={{
                border: "solid 1px #fff",
                borderRadius: "20px",
                padding: "10px",
              }}
            >
              <Button>
                <NavLink
                  to="/contact"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  <Typography sx={{ fontSize: "20px" }}>Contact Us</Typography>
                </NavLink>
              </Button>{" "}
            </Box>
          </Divider>
        </Box>
      </Box>
    </>
  );
};

export default HIW;
