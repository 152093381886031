import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import MenuNavButton from "./MenuNav";
import MenuSingleBtn from "./MenuSingleBtn";
import NavMenuResponsive from "./NavMenuResponsive";
import logo from "../../assets/logo.svg";
import QuoteBtn from "../New/mini-components/QuoteBtn";
import { NavLink } from "react-router-dom";

function ResponsiveAppBar() {
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: { md: "transparent", xs: "#fff" },
        backdropFilter: "blur(4px)",
        zIndex: "1000",
        height: { md: "80px" },
        padding: "5px",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              marginTop: "10px",
              marginLeft: "20px",
              display: { md: "flex", xs: "none" },
            }}
          >
            <NavLink to="/">
              <img alt="" src={logo} width="300px" />
            </NavLink>
          </Box>

          <Box sx={{ flexGrow: 2, display: { xs: "flex", md: "none" } }}>
            <NavMenuResponsive />
          </Box>
          <Box
            sx={{
              marginTop: "10px",
              marginLeft: "20px",
              display: { md: "none", xs: "flex" },
            }}
          >
            <NavLink to="/">
              <img alt="" src={logo} width="250px" />
            </NavLink>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingLeft: "90px",
              paddingRight: "90px",
              justifyContent: "space-evenly",
              marginTop: "10px",
            }}
          >
            <MenuSingleBtn title="Home" href_btn="/" />
            <MenuSingleBtn title="About" href_btn="/about" />
            <MenuSingleBtn title="Contact Us" href_btn="/contact" />
            {/*<MenuSingleBtn title="FAQs" href_btn="/faqs" />*/}
          </Box>
          <Box sx={{ flexGrow: 0, display: { md: "flex", xs: "none" } }}>
            <QuoteBtn title="Get A Quote" href="/get-a-quote" />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
