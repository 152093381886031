import {
  Alert,
  Box,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import QuoteBtn from "./QuoteBtn";

import home5 from "../../../assets/qw2000.png";

const Q2000 = (props) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: true,
    arrow: true,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    border: "solid 2px #005096",
  };

  return (
    <>
      <Box sx={{ textAlign: "left", marginTop: "50px" }}>
        <Stack sx={{ width: "80%", paddingTop: "20px" }} spacing={2}>
          <Alert severity="success">
            Haydropack X units can produce up to 1000 liters a day of filtered
            drinking water.
          </Alert>

          {/*  <QuoteBtn
            title="More About QW100 Product"
            href={handleClick}
            onClick={handleClick}
          />*/}
        </Stack>

        <Modal
          open={open}
          onClose={handleClose}
          sx={{ backdropFilter: "blur(4px)", overflow: "scroll" }}
        >
          <Box sx={style}>
            <Grid container lg={12}>
              <Grid item lg={5}>
                <Box sx={{ textAlign: "left" }}>
                  <Chip label="FDM" />
                  <Typography variant="h3">
                    Haydropack X atmospheric water generator
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={7}>
                <Box
                  sx={{
                    borderRadius: "20px",
                    border: "solid 2px #000",
                    textAlign: "center",
                  }}
                >
                  <Slider {...settings}>
                    <Box>
                      <img alt="" src={home5} width="100px" />
                    </Box>
                  </Slider>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ marginY: "20px" }}>
              <Divider />
            </Box>
            <Grid container lg={12}>
              <Grid item lg={8}>
                <Typography
                  variant="h4"
                  sx={{ fontSize: "25px", fontWeight: "500" }}
                >
                  Description
                </Typography>
                <Box sx={{ marginY: "10px" }}>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    The revolutionary technology of drinking water production
                  </Typography>
                </Box>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    paddingRight: "10%",
                  }}
                >
                  QUERYWATER is a patented technology for producing clean
                  drinking water directly from the air using a condensation
                  process. Atmospheric water generator, type: QW2000 produces up
                  to 2000 liters of drinking water per day. .{" "}
                </Typography>
                <Box sx={{ marginY: "20px" }}>
                  <Divider />
                </Box>

                <Box sx={{ marginY: "10px" }}>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    Easy installation of the unit{" "}
                  </Typography>
                </Box>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    paddingRight: "10%",
                  }}
                >
                  Installation of the QW2000 unit is very easy, resp. just plug
                  it in and you can start using it.
                </Typography>
                <QuoteBtn title="Get Instant Quote" href="/get-a-quote" />
              </Grid>
              <Box sx={{ marginY: "0px", marginRight: "15px" }}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: "310px" }}
                />
              </Box>
              <Grid item lg={3}>
                <Box>Key features of the QW20 </Box>
                <Box sx={{ overflow: "scroll" }}>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 300,
                      bgcolor: "#fff",
                    }}
                  >
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary=""
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              <span style={{ marginLeft: "-5px" }}>-</span>{" "}
                              Production of
                            </Typography>
                            {" —  up to 2000 liters of drinking water per day"}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary=""
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              <span style={{ marginLeft: "-5px" }}>-</span>{" "}
                              Excellent
                            </Typography>
                            {
                              " —  water quality meeting US EPA and World Health Organization standards"
                            }
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary=""
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              <span style={{ marginLeft: "-5px" }}>-</span> UV
                              filtration
                            </Typography>
                            {
                              " —  UV filtration removes up to 99.9% of bacteria and viruses"
                            }
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ marginY: "20px" }}>
              <Divider />
            </Box>
            <Box sx={{ display: "flex" }}>
              Download:{" "}
              <Box sx={{ marginX: "5px" }}>
                <a href="https://querywater.cz/wp-content/uploads/2021/05/ECOONE_QUERY%C2%AEWATER-QW2000_EN.pdf">
                  Technical datasheet QUERYWATER QW2000 EN
                </a>
              </Box>
              |
              <Box sx={{ marginX: "5px" }}>
                <a href="https://querywater.cz/wp-content/uploads/2020/02/ECOONE_QUERY%C2%AEWATER_water-quality-certification-EN.pdf">
                  Water analysis protocol SZÚ EN
                </a>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default Q2000;
